import React from 'react';
import '../css/Benefits.css';
import carIcon from '../images/new-car-transparent.png';
import kabaaIcon from '../images/makka-kabaa-transparent.png';
import travelIcon from '../images/traveling-transparent.png';
import savingsIcon from '../images/euro-savings-bag-transparent.png';
import houseIcon from '../images/house-renovation-transparent.png';

const Benefits = () => {
  const benefits = [
    { id: 1, icon: carIcon, label: 'Nieuwe Auto' },
    { id: 2, icon: kabaaIcon, label: 'Oumrah' },
    { id: 3, icon: travelIcon, label: 'Reizen' },
    { id: 4, icon: houseIcon, label: 'Huis Renovatie' },
    { id: 5, icon: savingsIcon, label: 'Of gewoon: Sparen' },
  ];

  return (
    <div className="benefits-container">
      <h2>Met de Rosca App Samen Sparen Voor</h2>
      <div className="benefits-grid">
        {benefits.map((benefit) => (
          <div key={benefit.id} className="benefit-box">
            <img src={benefit.icon} alt={benefit.label} className="benefit-icon" />
            <p>{benefit.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
