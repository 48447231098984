import React from 'react';
import { Link } from 'react-router-dom';
import '../css/404.css'; // Create a CSS file to style your 404 page

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1>404</h1>
      <h2>Pagina niet gevonden</h2>
      <p>Sorry, de pagina die je zoekt bestaat niet.</p>
      <Link to="/" className="home-link">
        Terug naar Home
      </Link>
    </div>
  );
};

export default NotFound;
