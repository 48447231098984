import React, { useState } from 'react';
import '../css/FAQ.css';

const FAQ = () => {
  const [selectedSubject, setSelectedSubject] = useState('Rosca app');
  const [activeQuestions, setActiveQuestions] = useState({});

  const subjects = {
    'Rosca app': [
      { question: 'Wat is de Rosca app?', answer: 'De Rosca app is een digitaal platform voor het beheren en deelnemen aan ROSCA-groepen, waarbij deelnemers bijdragen en fondsen ontvangen op basis van een rotatieschema.' },
      { question: 'Hoe werkt de Rosca app?', answer: 'De Rosca app automatiseert het hele proces, van het beheren van bijdragen tot het uitbetalen van fondsen, zodat gebruikers zich geen zorgen hoeven te maken over het organiseren van groepen of het opvolgen van betalingen.' },
      { question: 'Wat zijn de voordelen van het gebruik van de Rosca app?', answer: 'De voordelen omvatten geautomatiseerde bijdragen en betalingen, betrouwbare identiteitsverificatie, juridische zekerheid, en continuïteit van ROSCA-cirkels, zelfs bij betalingsuitval van een deelnemer.' },
      { question: 'Hoe vaak worden nieuwe ROSCA-cirkels toegevoegd?', answer: 'Nieuwe ROSCA-cirkels worden maandelijks toegevoegd, waardoor er altijd voldoende opties zijn voor elke gebruiker.' },
    ],
    'Account': [
      { question: 'Hoe maak ik een account aan?', answer: 'Om een account aan te maken, download de app en volg het registratieproces waarbij u uw persoonlijke gegevens invoert en een identificatieproces doorloopt via iDIN en een ID-scan.' },
      { question: 'Hoe wijzig ik mijn accountgegevens?', answer: 'U kunt uw accountgegevens wijzigen door in te loggen op de app en naar het accountinstellingenmenu te gaan, waar u uw informatie kunt aanpassen.' },
      { question: 'Hoe kan ik mijn account beveiligen?', answer: 'U kunt uw account beveiligen door een sterk wachtwoord te kiezen en de tweefactorauthenticatie (2FA) in te schakelen via de app-instellingen.' },
    ],
    'Fondsen beheer': [
      { question: 'Hoe worden de fondsen beheerd in de ROSCA-app?', answer: 'Alle fondsen worden centraal beheerd door Alzijdigi. Dit zorgt voor een veilige en betrouwbare manier om bijdragen te verzamelen en uit te betalen aan de deelnemers.' },
      { question: 'Wat gebeurt er als een deelnemer zijn bijdrage niet op tijd betaalt?', answer: 'Als een deelnemer zijn bijdrage niet op tijd betaalt, neemt Alzijdigi tijdelijk hun plaats in en betaalt de bijdrage. De deelnemer wordt vervolgens juridisch vervolgd om de gemiste betaling te innen.' },
      { question: 'Hoe wordt de veiligheid van mijn geld gegarandeerd?', answer: 'De ROSCA-app maakt gebruik van geavanceerde beveiligingstechnologieën en betrouwbare ID-verificatietools zoals iDIN om ervoor te zorgen dat uw geld veilig wordt beheerd.' },
      { question: 'Kan ik mijn bijdragen volgen?', answer: 'Ja, u kunt uw bijdragen en de status van uw ROSCA-cirkel op elk moment volgen via de app. Dit zorgt voor transparantie en vertrouwen in het proces.' },
      { question: 'Hoe weet ik wanneer ik aan de beurt ben om de fondsen te ontvangen?', answer: 'De app stuurt u meldingen en updates over de voortgang van uw ROSCA-cirkel, inclusief de datum waarop u aan de beurt bent om de fondsen te ontvangen.' },
      { question: 'Wat gebeurt er met de fondsen als een ROSCA-cirkel niet vol raakt?', answer: 'Als een ROSCA-cirkel niet volledig gevuld is, neemt Alzijdigi de open plekken in om ervoor te zorgen dat de cirkel doorgaat zoals gepland. Hierdoor worden de deelnemers niet benadeeld en blijft het proces soepel verlopen.' },
    ],
    'Betalingen': [
      { question: 'Hoe kan ik mijn bijdragen betalen?', answer: 'U kunt uw bijdragen betalen via de app, die verschillende betaalmethoden ondersteunt, waaronder bankoverschrijvingen en automatische incasso.' },
      { question: 'Wat gebeurt er als ik een betaling mis?', answer: 'Als u een betaling mist, neemt Alzijdigi uw plaats tijdelijk in om ervoor te zorgen dat de ROSCA-cirkel doorgaat. U wordt daarna benaderd om de gemiste betaling te regelen.' },
      { question: 'Kan ik automatische betalingen instellen?', answer: 'Ja, u kunt automatische betalingen instellen via de app om ervoor te zorgen dat uw bijdragen altijd op tijd worden voldaan.' },
      { question: 'Zijn er extra kosten verbonden aan betalingen?', answer: 'De app brengt geen extra kosten in rekening voor standaardbetalingen. Eventuele kosten worden duidelijk vermeld voordat een transactie wordt bevestigd.' },
      { question: 'Hoe weet ik wanneer mijn volgende betaling verschuldigd is?', answer: 'U ontvangt meldingen en herinneringen via de app over uw aankomende betalingen, zodat u altijd op de hoogte bent van uw verplichtingen.' },
    ],
    'Circle status': [
      { question: 'Hoe kan ik de status van mijn ROSCA-cirkel volgen?', answer: 'U kunt de status van uw ROSCA-cirkel volgen via de app, die real-time updates en meldingen biedt over de voortgang van uw groep.' },
      { question: 'Wat gebeurt er als een ROSCA-cirkel niet volledig gevuld is?', answer: 'Als een ROSCA-cirkel niet volledig gevuld is, neemt Alzijdigi de open plekken in zodat de cirkel zoals gepland kan doorgaan.' },
      { question: 'Kan ik zien wie de andere deelnemers in mijn ROSCA-cirkel zijn?', answer: 'Voor privacyredenen worden de identiteiten van andere deelnemers niet gedeeld. U kunt echter de voortgang van de cirkel en uw positie volgen via de app.' },
      { question: 'Hoe vaak wordt de status van de cirkel bijgewerkt?', answer: 'De status van de cirkel wordt real-time bijgewerkt, zodat u altijd up-to-date informatie heeft over uw ROSCA-cirkel.' },
      { question: 'Wat gebeurt er als ik wil stoppen met mijn deelname aan een cirkel?', answer: 'Als u wilt stoppen met uw deelname, moet u contact opnemen met de klantenservice van Alzijdigi voor verdere instructies en mogelijke consequenties.' },
    ],
    'Documenten': [
      { question: 'Welke documenten zijn nodig om deel te nemen aan een ROSCA-cirkel?', answer: 'Om deel te nemen moet u een identiteitsbewijs en een bewijs van adres indienen, samen met de identificatie via iDIN en een ID-scan.' },
      { question: 'Waar kan ik mijn ondertekende contracten en andere documenten vinden?', answer: 'U kunt uw ondertekende contracten en andere relevante documenten vinden in uw account binnen de app, onder het menu "Documenten".' },
      { question: 'Hoe lang worden mijn documenten bewaard?', answer: 'Uw documenten worden bewaard zolang uw account actief is en voldoen aan de wettelijke bewaartermijnen. U kunt uw documenten op elk moment inzien via de app.' },
      { question: 'Kan ik kopieën van mijn documenten downloaden?', answer: 'Ja, u kunt kopieën van uw documenten downloaden via de app. Ga naar het menu "Documenten" en selecteer de gewenste bestanden om ze op uw apparaat op te slaan.' },
      { question: 'Wat moet ik doen als ik een fout ontdek in mijn documenten?', answer: 'Als u een fout ontdekt in uw documenten, neem dan onmiddellijk contact op met de klantenservice van Alzijdigi om de correcties door te voeren.' },
    ],
  };

  const toggleQuestion = (subject, index) => {
    setActiveQuestions((prev) => ({
      ...prev,
      [subject]: prev[subject] === index ? null : index,
    }));
  };

  return (
    <div className="faq-container" id="faq">
      <h2>FAQ's</h2>
      <h2>Hier vind u de meeste gestelde vragen en belangerijke informatie over de Rosca App</h2>
      <div className="faq-content">
        <div className="faq-subjects">
          {Object.keys(subjects).map((subject) => (
            <div
              key={subject}
              className={`faq-subject ${selectedSubject === subject ? 'active' : ''}`}
              onClick={() => setSelectedSubject(subject)}
            >
              {subject}
            </div>
          ))}
        </div>
        <div className="faq-questions">
          {subjects[selectedSubject].map((item, index) => (
            <div key={index} className="faq-question">
              <div
                className={`faq-question-header ${activeQuestions[selectedSubject] === index ? 'active' : ''}`}
                onClick={() => toggleQuestion(selectedSubject, index)}
              >
                {item.question}
                <span className={`arrow ${activeQuestions[selectedSubject] === index ? 'open' : ''}`}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
              </div>
              {activeQuestions[selectedSubject] === index && (
                <div className="faq-answer">
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
