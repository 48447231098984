import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import HoeRoscaWerkt from './components/HoeRoscaWerkt';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import TermsOfUse from './components/TermsofUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import NotFound from './components/404'; // Import the 404 component
import Navbar from './components/Navbar';
import Loading from './components/Loading';

import './css/App.css';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to show the loading indicator
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="App">
        {loading && <Loading />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hoe-rosca-werkt" element={<HoeRoscaWerkt />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gebruiksvoorwaarden" element={<TermsOfUse />} />
          <Route path="/privacyverklaring" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} /> {/* This handles all undefined routes */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
