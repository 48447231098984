import React from 'react';
import '../css/TermsofUse.css';

const TermsOfUse = () => {
  return (
    <div className="terms-container">
      <h1>Gebruiksvoorwaarden</h1>
      <p>
        Welkom bij Rosca App Nederland. Door gebruik te maken van onze diensten, gaat u akkoord met de volgende voorwaarden.
      </p>
      <h2>1. Acceptatie van de voorwaarden</h2>
      <p>
        Door onze applicatie of website te gebruiken, stemt u in met deze gebruiksvoorwaarden. Als u niet akkoord gaat, verzoeken we u onze diensten niet te gebruiken.
      </p>
      <h2>2. Wijzigingen in de voorwaarden</h2>
      <p>
        Wij behouden ons het recht voor om deze voorwaarden op elk moment te wijzigen. U wordt aangeraden deze pagina regelmatig te controleren op updates.
      </p>
      <h2>3. Gebruikersverantwoordelijkheden</h2>
      <p>
        U bent verantwoordelijk voor het naleven van alle toepasselijke wetten bij het gebruik van onze diensten en voor het beschermen van uw accountinformatie.
      </p>
      <h2>4. Beperking van aansprakelijkheid</h2>
      <p>
        Rosca App Nederland en Alzijdigi zijn niet aansprakelijk voor enige directe of indirecte schade die voortvloeit uit het gebruik van onze diensten.
      </p>
      <h2>5. Toepasselijk recht</h2>
      <p>
        Op deze voorwaarden is het Nederlandse recht van toepassing. Geschillen worden voorgelegd aan de bevoegde rechtbank in Nederland.
      </p>
      <p>
        Voor meer informatie of vragen over deze voorwaarden, neem contact met ons op via <a href="/contact">onze contactpagina</a>.
      </p>
    </div>
  );
};

export default TermsOfUse;
