import React from 'react';
import '../css/Footer.css';
import logoImage from '../images/logo.png'; // Ensure you have the logo image in the images folder
import { FaFacebook, FaInstagram, FaWhatsapp, FaTwitter, FaTiktok, FaYoutube, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section logo-social-section">
          <img src={logoImage} alt="Logo" className="footer-logo" />
          <div className="social-icons">
            <a href="https://www.instagram.com/rosca.app" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
            <a href="https://www.tiktok.com/@rosca.app" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.youtube.com/@RoscaNederland" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
            <a href="https://x.com/RoscaApp_NL" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.linkedin.com/company/rosca-app-nederland/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
          </div>
        </div>
       
        <div className="footer-section text-section">
          <p className="legal-text">
            Rosca App Nederland fungeert als beheerder en tussenpersoon tussen gebruikers door het aanbieden van een mobiele applicatie, beheerd door Alzijdigi B.V. (KvK: 94481660), die alle nodige functies biedt om samen in spaarcirkels te kunnen sparen en om beurtelings toegang te krijgen tot gezamenlijke fondsen. De betalingen en fondsen bevinden zich in een derdengeldenrekening, veilig opgeslagen volgens de wet. Deze worden niet door Rosca App Nederland of Alzijdigi beheerd, maar door een erkend derde betalingsbedrijf waarmee Alzijdigi, het moederbedrijf van Rosca App Nederland, samenwerkt. Alzijdigi fungeert enkel als beheerder tussen de deelnemende gebruikers en biedt maandelijks nieuwe spaarcirkels (spaarpotten) aan.
          </p>
        </div>
        <div className="footer-section links-section">
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#hoe-rosca-werkt">Hoe Rosca werkt</a></li>
            <li><a href="#faq">FAQ's</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
      <a href="/gebruiksvoorwaarden">Gebruiksvoorwaarden</a> | <a href="/privacyverklaring">Privacyverklaring</a>
      <p>© 2024 Rosca Nederland - <a href="https://www.Alzijdigi.nl" target="_blank" rel="noopener noreferrer">AlzijDigi B.V.</a></p>
      </div>
    </footer>
  );
};

export default Footer;
