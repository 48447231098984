import React, { useEffect } from 'react';
import '../css/HoeRoscaWerkt.css';

const HoeRoscaWerkt = () => {
  useEffect(() => {
    const handleScroll = () => {
      const circles = document.querySelectorAll('.circle-pattern');
      const windowHeight = window.innerHeight;

      circles.forEach(circle => {
        const rect = circle.getBoundingClientRect();
        if (rect.top < windowHeight) {
          circle.classList.add('expand');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="section-container-hrw" id="hoe-rosca-werkt">
      <h2>Hoe Rosca werkt</h2>
      <div className="video-container">
        <iframe 
          width="100%" 
          height="315" 
          src="" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>
      <div className="circle-pattern pattern1"></div>
      <div className="circle-pattern pattern2"></div>
    </div>
  );
}

export default HoeRoscaWerkt;
