import React from 'react';
import '../css/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacyverklaring</h1>
      <p>
        Bij Rosca App Nederland nemen we uw privacy zeer serieus. Deze privacyverklaring beschrijft hoe we persoonlijke gegevens verzamelen, gebruiken en beschermen wanneer u onze diensten gebruikt.
      </p>
      <h2>1. Verzamelde Gegevens</h2>
      <p>
        We verzamelen persoonlijke informatie zoals naam, e-mailadres, telefoonnummer en betaalinformatie wanneer u zich aanmeldt voor onze diensten of deelneemt aan spaarcirkels. Deze gegevens zijn essentieel om onze diensten goed te laten functioneren.
      </p>
      <h2>2. Gebruik van Gegevens</h2>
      <p>
        De verzamelde gegevens worden gebruikt om u toegang te geven tot de Rosca-app, betalingen te verwerken en communicatie met u te onderhouden. We kunnen ook gegevens gebruiken voor analyse- en verbeteringsdoeleinden.
      </p>
      <h2>3. Delen van Gegevens</h2>
      <p>
        Uw gegevens worden niet gedeeld met derden, behalve met onze erkende betalingsprovider voor het veilig verwerken van transacties en waar wettelijk vereist.
      </p>
      <h2>4. Gegevensbeveiliging</h2>
      <p>
        We nemen de bescherming van uw gegevens serieus en hanteren strikte veiligheidsmaatregelen om uw persoonlijke informatie te beschermen tegen ongeautoriseerde toegang, wijziging, openbaarmaking of vernietiging.
      </p>
      <h2>5. Uw Rechten</h2>
      <p>
        U heeft het recht om uw gegevens in te zien, te corrigeren of te verwijderen. Neem contact met ons op via <a href="/contact">onze contactpagina</a> om uw verzoek in te dienen.
      </p>
      <h2>6. Wijzigingen aan deze Privacyverklaring</h2>
      <p>
        We behouden ons het recht voor om deze privacyverklaring op elk moment te wijzigen. Eventuele wijzigingen worden op deze pagina bijgewerkt.
      </p>
      <p>
        Heeft u vragen over ons privacybeleid? Neem gerust contact met ons op via <a href="/contact">onze contactpagina</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
